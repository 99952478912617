const WEB_ORIGIN = window?.location?.origin ? `${window?.location?.origin}/` : "https://archery.devmsk.com/"
export const API_URL = window?.location?.hostname.includes("devmsk.com") ? WEB_ORIGIN : "https://archery.devmsk.com/";
// export const API_URL = window?.location?.hostname.includes("devmsk.com") ? WEB_ORIGIN : `http://localhost:5050/`;
// export const API_URL = process.env.REACT_APP_DEV_BACKEND_URL;

// Links
export const CHECK_SUBSCRIPTION_URL = `${API_URL}api/v1/check-subscription`;
export const GET_HEROES_API_URL = `${API_URL}api/v1/get-heroes/`;
export const GET_TITANS_API_URL = `${API_URL}api/v1/get-titans/`;
export const QUEUE_ADD_API_URL = `${API_URL}api/v1/queue/add`;
export const QUEUE_CREATE_API_URL = `${API_URL}api/v1/queue/create`;
export const UPDATE_HERO_POWER_API_URL = `${API_URL}api/v1/update-hero-power/`;
export const MENU_CHECK_API_URL = `${API_URL}api/v1/menu/check`;
export const MY_HERO_PACK_API_URL = `${API_URL}api/v1/my/pack/hero-pack`;
export const MY_HERO_PACK_SET_ORDER_API_URL = `${API_URL}api/v1/my/pack/hero-pack-set-order`;
export const ADD_HEROES_WAR_API_URL = `${API_URL}api/v1/heroes-war-add/`;
export const DELETE_HEROES_WAR_API_URL = `${API_URL}api/v1/heroes-war/`;

export const HERO_COUNTERPACK_FIND_API_URL = `${API_URL}api/v1/pack/find-counterpack`;
export const UPDATE_TITAN_POWER_API_URL = `${API_URL}api/v1/update-titan-power/`;
export const ADD_WAR_API_URL = `${API_URL}api/v1/war-add/`;
export const DELETE_WAR_API_URL = `${API_URL}api/v1/war/`;

export const GET_USERS_LIST_API_URL = `${API_URL}api/v1/screenshots/get-users/`;
export const UPLOAD_MS_SCREENSHOTS_API_URL = `${API_URL}api/v1/screenshots/upload/`;

export const ACCOUNT_API_URL = `${API_URL}api/v1/account/`;
export const ACCOUNT_GUILD_API_URL = `${API_URL}api/v1/account/guild`;
export const ACCOUNT_DELETE_API_URL = `${API_URL}api/v1/account/delete`;
export const GUILD_REQUEST_API_URL = `${API_URL}api/v1/guild/request`;
export const GUILD_DATA_API_URL = `${API_URL}api/v1/guild`;
export const GUILD_DATA_RENAME_API_URL = `${API_URL}api/v1/guild/rename`;

export const CHECK_DATA_API_URL = `${API_URL}api/v1/check-data`;

export const PROCESSING_QUERY_API_URL = `${API_URL}api/v1/processing-query/`;
export const GC_LOADING_HISTORY_API_URL = `${API_URL}api/v1/screen-loading-history/`;

export const MENU_API_URL = `${API_URL}api/v1/menu/`;

export const DATA_GET_API_URL = `${API_URL}api/v1/data/get/`;
export const REVIEW_API_URL = `${API_URL}api/v1/review/`;
export const PING_API_URL = `${API_URL}api/v1/ping/`;
